const data = {
  links: {
    freePythonLectureRegistration: "https://forms.gle/BZyWuCnFAUvKbDot5",
    generalRegistrationForm: "https://formfaca.de/sm/QwkYhS7Wm",
  },
  courseTopics: [
    {
      id: "pb",
      name: "Python pradedantiesiems",
      description: "Course for someone",
      price: 400,
      hours: 40,
    },
  ],
  courses: [
    {
      isPopular: true,
      id: "p-b",
      name: "Python pradedantiesiems",
      tags: ["python", "data-analysis", "beginner"],
      type: "Dieninis",
      days: "PATKPxx",
      groupSize: [8, 16],
      intensity: 40,
      price: 330,
      hours: 40,
      startDate: "2022-11-21",
      description:
        "Python yra populiariausia pasaulyje programavimo kalba. Python kodas yra lengvai skaitomas, o taikymo sričių - begalė, įskaitant tinklalapių kūrimą, mašininį mokymąsį, automatizavimą ir t.t. Šis kursas puikiai tinka norintiems įgauti Python programavimo pagrindus ir pasirinkti tolimesnę kryptį.",
      // descriptionExtra:
      //   "Šis kursas yra paremtas partnerių, pagal jį paruošta 500 studentų Vokietijoje.",
      link: "python-beginner",
      active: true,
      // https://schema.org/CourseInstance
      courseInstances: [
        // {
        //   courseMode: "online",
        //   startDate: "2022-11-28",
        //   endDate: "2022-12-02",
        //   isAccessibleForFree: false,
        //   courseWorkload: "40 kontaktinių valandų",
        //   instructor: { "@type": "Person", name: "Aidis Stukas" },
        //   eventAttendanceMode: "online",
        //   organizer: { "@type": "Organization", name: "Pirmas kodas" },
        //   offers: {
        //     "@type": "Offer",
        //     availability: "https://schema.org/InStock",
        //     price: 330,
        //     priceCurrency: "EUR",
        //     // url: "http://www.ticketfly.com/purchase/309433",
        //   },
        //   extraType: "dieninė",
        //   extraTypeEmoji: "🏙️",
        //   extraDayTime: "DD, 09:00-17:00 su pertrauka",
        //   // Todo later
        //   // eventSchedule: {},
        // },
        // {
        //   courseMode: "online",
        //   startDate: "2023-01-23",
        //   endDate: "2023-02-17",
        //   isAccessibleForFree: false,
        //   courseWorkload: "40 kontaktinių valandų",
        //   instructor: { "@type": "Person", name: "Aidis Stukas" },
        //   eventAttendanceMode: "online",
        //   organizer: { "@type": "Organization", name: "Pirmas kodas" },
        //   offers: {
        //     "@type": "Offer",
        //     availability: "https://schema.org/InStock",
        //     price: 330,
        //     priceCurrency: "EUR",
        //     // url: "http://www.ticketfly.com/purchase/309433",
        //   },
        //   extraType: "vakarinė",
        //   extraTypeEmoji: "🌆",
        //   extraDayTime: "DD, 18:00-20:00",
        //   // Todo later
        //   // eventSchedule: {},
        //   order: 2,
        // },
        // {
        //   courseMode: "online",
        //   startDate: "2023-01-28",
        //   endDate: "2022-02-19",
        //   isAccessibleForFree: false,
        //   courseWorkload: "40 kontaktinių valandų",
        //   instructor: { "@type": "Person", name: "Aidis Stukas" },
        //   eventAttendanceMode: "online",
        //   organizer: { "@type": "Organization", name: "Pirmas kodas" },
        //   offers: {
        //     "@type": "Offer",
        //     availability: "https://schema.org/InStock",
        //     price: 330,
        //     priceCurrency: "EUR",
        //     // url: "http://www.ticketfly.com/purchase/309433",
        //   },
        //   extraType: "savaitgalinė",
        //   extraTypeEmoji: "☀️",
        //   extraDayTime: "Šeštadieniais, 10:00-13:00",
        //   // Todo later
        //   // eventSchedule: {},
        //   order: 2,
        // },
        {
          courseMode: "online",
          startDate: "2023-03-27",
          endDate: "2023-04-14",
          isAccessibleForFree: false,
          courseWorkload: "40 kontaktinių valandų",
          instructor: { "@type": "Person", name: "Aidis Stukas" },
          eventAttendanceMode: "online",
          organizer: { "@type": "Organization", name: "Pirmas kodas" },
          offers: {
            "@type": "Offer",
            availability: "https://schema.org/InStock",
            price: 600,
            priceCurrency: "EUR",
            // url: "http://www.ticketfly.com/purchase/309433",
          },
          extraType: "dieninė",
          extraTypeEmoji: "🏙️",
          extraDayTime: "10:00-12:00 darbo dienomis",
          // Todo later
          // eventSchedule: {},
          order: 1,
        },
      ],
      position: 1,
    },
    {
      id: "p-da",
      name: "Duomenų analizė su Python",
      tags: ["python", "data-analysis", "pandas", "machine-learning"],
      type: "Vakarinis",
      days: "PATKPxx",
      intensity: 40,
      price: 800,
      hours: 80,
      startDate: "2022-10-02",
      endDate: "2022-10-20",
      description:
        "Python programavimo kalba yra lyderė duomenų analizės srityje. Versle Python keičia ir papildo Excel bei kitas duomenų analizės priemonės. Baigę šį kursą galėsite mikliai naršyti, tvarkyti, analizuoti ir pristatyti duomenis su Python. Baigę šį kursą galėsite studijuoti mašininį mokymąsį.",
      link: "duomenu-analitika-su-python",
      active: true,
      position: 2,
    },
    {
      id: "p-ds",
      name: "Duomenų mokslas ir mašininis mokymasis su Python",
      tags: ["python", "data-analysis", "pandas", "machine-learning"],
      type: "Vakarinis",
      days: "PATKPxx",
      intensity: 40,
      price: 1200,
      hours: 80,
      startDate: "2022-10-02",
      endDate: "2022-10-20",
      description:
        "Tai žingsnis po duomenų analitikos. Susipažinsime su mašininiu mokymusi. Įvaldysime sklearn biblioteką. Susipažinsime su Xgboost, LightGbm bei neuroninių tinklų bibliotekomis (TensorFlow, Pytorch).",
      link: "masininis-mokymasis-su-python",
      active: true,
      position: 3,
    },
    {
      id: "p-wd-be",
      name: "Backend Web dev su Python (Django arba FastAPI)",
      tags: ["python", "data-analysis", "pandas", "machine-learning"],
      type: "Vakarinis",
      days: "PATKPxx",
      intensity: 40,
      price: 800,
      hours: 80,

      description:
        "Norite kurti web aplikacijas? Python turi bent keletą tam skirtų bibliotekų. Šis kursas labiausiai tinka tiems, kurie mėgsta dirbi su duomenimis ir neturi noro gilintis į dizainą ir UX/UI klausimus. Baigę šį kursą galėsite kuri web aplikacijų backend dalį.",
      link: "tinklalapiu-kurimas-su-python",
      active: true,
      position: 4,
    },
    {
      id: "p-wd-fs",
      name: "Full stack Web dev su Python ir Javascript (React, Tailwind)",
      tags: ["python", "data-analysis", "pandas", "machine-learning"],
      type: "Vakarinis",
      days: "PATKPxx",
      intensity: 40,
      price: 1500,
      hours: 120,

      description:
        "Full-stackeris žmogus, kuris dažniausiai reikalingas mažoms ir/arba judrioms(agile) kompanijoms. Esant poreikiui galėsite sukurti visą aplikaciją arba galėsite specializuotis į viena iš sričių bet tuo pačiu suprast ką daro kolegos 'kitame gale'.",
      link: "tinklalapiu-kurimas-su-python-ir-react",
      active: true,
      position: 5,
    },
    {
      id: "p-ws",
      name: "Web scraping su Python",
      tags: ["python", "data-analysis", "pandas", "machine-learning"],
      type: "Vakarinis",
      days: "PATKPxx",
      intensity: 40,
      price: 400,
      hours: 40,

      description:
        "Dažnas IT projektas priklauso nuo duomenų. Vieša paslaptis, kad didelė dalis kompanijų duomenis tiesiog renka iš interneto. Tai sena praktika, bet besikeičianti kiekvienais metais. Išmoksime įvairių scraping technikų. Baigę kursą galėsite sukurti sprendimą kuris nuolat tvarkingai ir patikimai surenka duomenis iš vieno ar net kelių šaltinių.",
      link: "web-scraping-su-python",
      active: true,
      position: 6,
    },
  ],
}
export default data

// Python Dieniniai savaites trukmes  kursai 40 val
// Python Vakariniai Menesio trukmes kursai 40 val
// Python Savaitgaliniai savaites 40 val trukmes kursai

// Data analysis - 80 val
// Dieniniai
// Vakariniai
// Savaitgaliniai

// ML - linear regression, lgbm/xgboost, deep learning
// Dieniniai
// Vakariniai
// Savaitgaliniai

// Backend Web dev Django / Flask / FastAPI
// Dieniniai
// Vakariniai
// Savaitgaliniai

// Full stack
// Backend + frontend

// Dieniniai intensyvus -- turintiems daug laiko ir norintiems kuo greiciau gauti ziniu
// Dieniniai mazo intensyvumo - turintiems po truputi laiko ir norintiems siekt iek daugit
// Vakariniai
// Savaitgaliniai
