import { GlobeAltIcon } from "@heroicons/react/24/outline"
import { Fira_Code, Roboto_Serif, Roboto_Flex, Roboto } from "@next/font/google"
import type { NextPage } from "next"
import { NextSeo } from "next-seo"
import Head from "next/head"
import Link from "next/link"
import Script from "next/script"
import Image from "next/image"
import CourseCard from "../components/courseCard"
import data from "../components/data"
import YoutubeEmbed from "../components/youtube-embed"
import eu from "public/eu.png"
const firaCode = Fira_Code({
  weight: "400",
  display: "swap",
  style: "normal",
  preload: false,
  subsets: ["latin-ext"],
  // fallback: ["sans-serif"],
})

const structuredData = {
  "@context": "https://schema.org",
  graph: [
    {
      "@type": "Organization",
      address: {
        "@type": "PostalAddress",
        addressLocality: "Kaunas, Lithuania",
        postalCode: "LT-50423",
        streetAddress: "Birželio 23-iosios 8",
      },
      email: "info@pirmaskodas.lt",
      legalName: "MB Pirmas kodas",
      name: "Pirmas kodas",
      telephone: "+37064139571",
      url: "https://pirmaskodas.lt/",
      sameAs: [
        "https://rekvizitai.vz.lt/imone/pirmas_kodas/",
        "https://geltoni.lt/imones/pirmas-kodas-mb/327992",
        "https://kontaktai24.lt/imone/mb-pirmas-kodas/",
        "https://okredo.com/lt-lt/imone/mb-pirmas-kodas-306129624",
        "https://pirmas-kodas.vizitinekortele.lt/",
        "https://www.tax.lt/imones/84367-pirmas-projektas-mb",
      ],
      founder: {
        "@type": "Person",
        name: "Aidis Stukas",
        sameAs: [
          "https://www.aidisstukas.com/",
          "https://github.com/aidiss",
          "https://www.linkedin.com/in/aidis-stukas-2895b68/",
          "https://twitter.com/aidis_stukas",
          "https://stackoverflow.com/users/2631229/aidis",
        ],
      },
    },
    {
      "@type": "Event",
      name: "Nemokama programavimo pamoka",
      eventStatus: "https://schema.org/EventScheduled",
      eventAttendanceMode: "https://schema.org/OnlineEventAttendanceMode",
      location: { "@type": "VirtualLocation", name: "online" },
      startDate: "2022-11-08",
      schedule: {
        "@type": "Schedule",
        scheduleTimezone: "Europe/Vilnius",
        byDay: "Monday",
        startTime: "17:00",
        endTime: "18:00",
        // startDate: "2022-11-08T17:00:00",
        // endDate: "2023-11-08T17:00:00",
      },
      offers: {
        "@type": "Offer",
        // url: "https://www.example.com/event_offer/12345_201803180430",
        price: 0,
        priceCurrency: "EUR",
        availability: "https://schema.org/InStock",
        validFrom: "2022-05-21T12:00",
      },
      description: "Nemokama programavimo pamoka",
      performer: {
        "@type": "Person",
        name: "Aidis Stukas",
      },
      organizer: {
        "@type": "Organization",
        name: "Pirmas kodas",
        url: "https://pirmaskodas.lt",
      },
    },
  ],
}

const features = [
  {
    emoji: "🐍",
    name: "Python ekspertai",
    description: (
      <>
        Python - populiariausia pasaulyje programavimo kalba. Būtent šioje
        kalboje mes specializuojamės bendradarbiaudami su <> </>
        <Link href="https://www.python-academy.com/" target="_blank">
          PythonAcademy.com
        </Link>{" "}
        - Python mokymų ekspertais.
      </>
    ),
    icon: GlobeAltIcon,
  },
  // {
  //   name: "Nedideles grupes",
  //   description:
  //     "Nebus klausytoju. Programa pagal 10 metu patirti. Programa pagal 10 metu patirti. Programa pagal 10 metu patirti. Programa pagal 10 metu patirti. Programa pagal 10 metu patirti. Programa pagal 10 metu patirti. ",
  //   icon: ScaleIcon,
  // },
  {
    emoji: "🎯",
    name: "Praktiniai įgūdžiai",
    description:
      "Mokymai orientuoti į darbo rinkoje reikalingus įgūdžius. Negaištame laiko mažos vertės temoms. Sekame darbo rinkos tendencijas ir pagal jas atnaujiname mokymų medžiagą.",
  },
  {
    emoji: "👷",
    name: "Profesionalūs lektoriai",
    description:
      "Pirmas kodas dėstytojai turi 5+ metų programavimo patirtį. Šis reikalavimas užtikrina, kad įgūdžius įgausite maksimaliai kokybiškai ir greitai.",
  },
  {
    emoji: "🤙",
    name: "Papildomos konsultacijos",
    description:
      "Studentams teikiame konsultacijas ne tik pamokų metu. Žinome, jog klausimai gali iškilti bet kada. Operatyviai ir išsamiai į juos atsakome studentams priimtina forma (video skambutis, žinutės ir kt.).",
  },
  {
    emoji: "😌",
    name: "Sąžiningai vertiname potencialą",
    description:
      "Daugelis mokyklų yra pasiruošusios paimti iš Jūsų pinigus, net jei netiki Jūsų, kaip programuotojo, karjeros potencialu.",
  },
  {
    emoji: "💸",
    name: "Pinigų grąžinimas",
    description:
      "Netiko kursai? Taikome sąžiningą ir dosnią pinigų grąžinimo politiką.",
  },
]

const Home: NextPage = () => {
  const pythonBeginnersCourse = data.courses.filter(
    (course) => course.id === "p-b",
  )[0]

  return (
    <>
      <NextSeo
        title="Pirmas kodas"
        description="Pirmas kodas - programavimo mokymai. Mokome programuoti su Python, kurti tinklalapius, atlikti duomenų analizę bei surinkimą, kurti mašininio mokymo modelius."
        canonical="https://pirmaskodas.lt"
        openGraph={{
          url: "https://pirmaskodas.lt",
          title: "Pirmas kodas",
          description: "Pirmas kodas programavimo kursai",
          images: [
            {
              url: "https://pirmaskodas.lt/main.png",
              width: 1200,
              height: 628,
              alt: "Pirmas kodas",
              type: "image/png",
            },
          ],
          site_name: "Pirmas kodas",
        }}
        twitter={{
          handle: "@pirmaskodas",
          cardType: "summary_large_image",
        }}
      />
      <Head>
        <title>Pirmas kodas</title>
        <meta
          name="description"
          content="Pirmas kodas yra programavimo mokymų kompanija kurios specialybė yra Python programavimo kalba. Teikiame tinklalapių kūrimo, duomenų analitikos ir kitu mokymus."
        />
        {/*  🏆💯🎯🥇 */}
      </Head>
      <Script
        id="schema1"
        type="application/ld+json"
        dangerouslySetInnerHTML={{ __html: JSON.stringify(structuredData) }}
      ></Script>

      <article className="prose prose-2xl w-auto dark:prose-invert">
        <div className="">
          <Image
            height={318}
            width={300}
            src={eu}
            alt="Funded by EU"
            className="invert dark:invert-0"
          />
          <h1 className="text-4xl">EU projects</h1>
          <div className="text-2xl">
            <h2>
              Unified document and communication language model and an
              intelligent medical language model for healthcare documents
              development
            </h2>
          </div>
          <div className="">
            <p>
              UAB Taikomasis dirbtinis intelektas has launched the project{" "}
              <span className="highlight">
                Development of a unified document and communication language
                model and an intelligent medical language model for healthcare
                documents
              </span>{" "}
              No 02-020-K-0013. The project is funded by the European Regional
              Development Fund.
            </p>
            <p>
              <span className="highlight">Project partner:</span> MB Pirmas
              kodas.
            </p>
            <p>
              <span className="highlight">Project budget:</span> € 1 486 827,
              financing by EU funds up to € 1 107 546
            </p>
            <p>Duration: June 10, 2024 — June 10, 2026.</p>
            <p>
              {`
              The project aims to develop a unified language model for documentation and communication and an intelligent medical language model for healthcare documents. The developed smart language model will focus on identifying potential business gaps and suggesting improvements. In addition, the system will predict the company's financial performance based on the content of the analysed documentation.`}
            </p>
            <p>
              {" "}
              {`
              The uniqueness of this model lies in its ability to retrospectively analyse past projects and generate adapted plans for future situations. This will enable the company to allocate human and financial resources more efficiently. The model will be integrated with the company's existing systems and adapted to comply with sector-specific regulations and rules, ensuring ethically and legally sustainable solutions.`}
            </p>
            <p>
              {" "}
              {`
              The project will contribute to the development of new high
              value-added products, allowing researchers to participate in the
              company's research and experimental development activities,
              promoting early pilot production of the new product developed and
              market readiness activities.`}
            </p>
          </div>
        </div>
      </article>
    </>
  )
}

export default Home
